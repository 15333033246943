<template>
  <v-card
    flat
    class="pa-3 mt-1"
  >
    <v-card-title class="d-flex align-center justify-center py-0">
      <div
        class="d-flex align-center"
      >
        <v-img
          :src="require(`@/assets/images/logos/logo.png`)"
          max-width="300px"
          alt="logo"
          contain
          class="me-0 "
        ></v-img>
      </div>
    </v-card-title>
    <v-form class="multi-col-validation">
      <v-card-text class="pt-1">
        <p class="text-h6 text-center">Make a Deposit</p>
        <input type="hidden" v-model="payment_ref" ref="payment_ref" id="payment_ref">
        <v-row>
          <v-col cols="10" class="py-0 my-1">
            <v-text-field
              v-model="userName"
              ref="userName"
              outlined dense
              label="Search username, phone number, unique code..."
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="py-0 my-1">
            <v-btn
              color="success"
              class="me-3"
              fab small
              @click="getUsers()"
            >
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-select
              v-model="selectedUser"
              ref="selectedUser"
              :items="users"
              item-text="username"
              item-value="id"
              outlined dense
              label="Select a user"
              @change="getUserDetails()"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-text-field
              v-model="phoneNumber"
              ref="phoneNumber"
              outlined
              dense
              label="User Phone Number"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-select
              v-model="selectedLoanCampaign"
              ref="selectedLoanCampaign"
              outlined return-object
              dense
              :items="loans_campaigns"
              item-text="loan_campaign_name"
              item-value="id"
              label="Select Loan/Campaign"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-text-field
              v-model="amount"
              ref="amount"
              outlined
              dense
              label="Amount"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <!-- current password -->
            <v-text-field
              v-model="currentPassword"
              ref="currentPassword"
              :type="isCurrentPasswordVisible ? 'text' : 'password'"
              :append-icon="isCurrentPasswordVisible ? mdiEyeOffOutline:mdiEyeOutline"
              label="Input Current Password"
              outlined
              dense
              @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-checkbox label="Send SMS to Client containing campaign status, completion rate, deposited amount, date and topup" hide-details class="me-3 mt-1"></v-checkbox>
          </v-col>
          
          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-checkbox label="Send Email to Client" hide-details class="me-3 mt-1"></v-checkbox>
          </v-col>
          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-checkbox label="Send Notification to Client" hide-details class="me-3 mt-1"></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="text-end">
        <spinner v-if="loading" class="text-end"></spinner>
        <v-btn 
          v-else
          color="primary"
          class="me-3 mt-3"
          @click="depositMoneyCampaign()"
        >
          Deposit
        </v-btn>

      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {
    return {
      loading: false,
      userName: "",
      selectedUser: "",
      phoneNumber: "",
      selectedLoanCampaign: null,
      isCurrentPasswordVisible: false,
      currentPassword: "",
      amount: "",
      payment_ref: "",
      users: [],
      loans_campaigns: [],
      mdiKeyOutline,
      mdiLockOpenOutline,
      mdiEyeOffOutline,
      mdiEyeOutline,
    }
  },
  
  mounted(){
      // executes these after the page has been mounted
      this.getDepositPaymentRef();
      document.title = "Topup: Savings | Deposit into Campaign"
  },

  methods:{
    getUsers(){
      axios
        .get('/api/v1/topup/get/clients/'+this.userName+'/')
        .then(response => {
          this.users = response.data  // get the data and fill into users
          // this.selectedUser = response.data[0]["id"]
          console.log(this.users)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    getUserDetails(){
      axios
        .get('/api/v1/topup/get/client/information/'+this.selectedUser+'/')
        .then(response => {
          console.log(response.data)
          this.phoneNumber = response.data['phone_number']  
          this.loans_campaigns = response.data['loans_campaigns'] 
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    async getDepositPaymentRef(){
        await axios
            .get('/api/v1/topup/create/user/deposits/payment_ref/')
            .then(response => {
              this.payment_ref = response.data
              console.log(this.payment_ref)
            })
            .catch(error => {
                if (error.response){
                    this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }else{
                    this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
            })
    },

    async depositMoneyCampaign(){
        if(this.payment_ref === ""){
            this.$store.commit('setSnackBarMessage', "Please an error occured with transaction, your reference code is null.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            window.location.reload()  // to reload and get another payment reference. this is for secuirity reasons
        }
        else if(!this.selectedLoanCampaign){
            this.$store.commit('setSnackBarMessage', "Please select the client's campaign")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.selectedLoanCampaign.focus()  // this causes a an auto fucos to the element
        }
        else if(this.amount === "" || String(Number(this.amount)) === "NaN"){
            if(this.amount === ""){
                this.$store.commit('setSnackBarMessage', "Please input the amount")
                this.$store.commit('setSnackBarColor', "red darken-4")
                this.$store.commit('activateSnackBar', true)
                this.$refs.amount.focus()  // this causes a an auto fucos to the element
            }
            else{
                this.$store.commit('setSnackBarMessage', "Please input only numbers for the amount")
                this.$store.commit('setSnackBarColor', "red darken-4")
                this.$store.commit('activateSnackBar', true)
                this.$refs.amount.focus()  // this causes a an auto fucos to the element
            }
        }
        else if(this.currentPassword === ""){
            this.$store.commit('setSnackBarMessage', "Please input ur password.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
        }
        else {
            this.loading = true
  
            let formData = new FormData()
            if (this.selectedLoanCampaign["loan_campaign_name"].includes("Loan:")){
              // formData.append('campaign', this.selectedLoanCampaign)
              formData.append('loan', this.selectedLoanCampaign["id"])
              formData.append('payment_ref', this.payment_ref)
              formData.append('amount', this.amount)
              formData.append('password', this.currentPassword)
            }
            else if (this.selectedLoanCampaign.loan_campaign_name.includes("Campaign:") ){
              formData.append('campaign', this.selectedLoanCampaign["id"])
              // formData.append('loan', this.selectedLoanCampaign)
              formData.append('payment_ref', this.payment_ref)
              formData.append('amount', this.amount)
              formData.append('password', this.currentPassword)
            }

            await axios
                .post('/api/v1/topup/deposits/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(response => {
                    console.log(this.payment_ref, response.data)
                    this.$store.commit('setSnackBarMessage', response.data[0].text)
                    this.$store.commit('setSnackBarColor', response.data[0].color)
                    this.$store.commit('activateSnackBar', true)
                    this.$store.commit('setSnackBarTimeOut', 8000)  // set time out for snack bar to 6seconds and remember too reset after 

                    if (response.data[0].category === "success"){
                      let router = this.$router
                      setTimeout(function(){
                          router.push('/savings/transactions')
                      }, 4000)
                    }
                })
                .catch(error => {
                    if (error.response){
                        this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }else{
                        this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }
                })

            this.loading = false
        }

    },
  },
}
</script>
